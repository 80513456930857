export class ValidationConstants {
  static MsisdnRegex = '\\+[0-9]{9,15}';

  static PostalCodeRegex = {
    PT: '([0-9]{4})(-[0-9]{3})?',
  };

  static PasswordMinLength = 8;
  static PasswordMinUpperCharsCount = 1;
  static PasswordMinDigitsCount = 1;
  static PasswordMinLettersCount = 1;

  static UrlRegex =
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$';
}
