import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { User, UserApiResponseData } from 'src/app/authentication/models/user.model';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { UserRole, UserRoleResponseData } from '../../authentication/models/user-role.model';
import { ChannelType } from 'src/app/shared/constants/global.constants';

export type CmEventSubscription = {
  key: string;
  channels: ChannelType[];
};

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private roles$ = new BehaviorSubject<UserRole[]>(null);

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.authenticatedStateChange
      .pipe(
        filter((authenticated) => authenticated),
        take(1),
        switchMap(() => this.fetchRoles())
      )
      .subscribe(() => {});
  }

  /**
   * Get a User data by id
   */
  getUser(userId: number): Observable<User> {
    return this.http.get<UserApiResponseData>(`users/${userId}`).pipe(map((userData) => new User(userData)));
  }

  /**
   * Get all platform users
   */
  getAllUsers(): Observable<User[]> {
    return this.http.get<UserApiResponseData[]>(`users`).pipe(map((users) => users.map((user) => new User(user))));
  }

  /**
   * Delete a User from the platform
   */
  deleteUser(user: User) {
    return this.http.delete<UserApiResponseData[]>(`users/${user.id}`);
  }

  /**
   * Get a existing User Role information
   */
  getRole(id: number) {
    return this.http.get<UserRoleResponseData>(`users/roles/${id}`).pipe(map((response) => new UserRole(response)));
  }

  /**
   * Get all the existsing Roles
   */
  getAllRoles() {
    return this.roles$.asObservable().pipe(
      filter((roles) => !!roles),
      take(1)
    );
  }

  fetchRoles() {
    return this.http.get<UserRoleResponseData[]>(`users/roles`).pipe(
      map((roles) => roles.map((role) => new UserRole(role))),
      tap((roles) => this.roles$.next(roles))
    );
  }

  /**
   * Add or update a User Role
   */
  upsertUserRole(role: UserRole) {
    const requestBody = role.toRequestModel();
    const request = role.id
      ? this.http.put<UserRoleResponseData>(`users/roles/${role.id}`, requestBody)
      : this.http.post<UserRoleResponseData>(`users/roles`, requestBody);

    return request.pipe(
      switchMap(() => this.fetchRoles()),
      map((updatedRoles) => updatedRoles.find((updatedRole) => updatedRole.id === role.id))
    );
  }

  /**
   * Delete a User Role
   */
  deleteUserRole(category: UserRole) {
    return this.http.delete(`users/roles/${category.id}`).pipe(switchMap(() => this.fetchRoles()));
  }

  /**
   * Inserts or updates a User
   * (if Id given it is considered as update, otherwise as a insert)
   * @param user
   * @param id
   */
  upsertUser(user: User): Observable<User> {
    const userData = user.toRequestModel();

    const request = user.id
      ? this.http.put<UserApiResponseData>(`users/${user.id}`, userData)
      : this.http.post<UserApiResponseData>('users', userData);

    return request.pipe(map((user) => new User(user)));
  }

  /**
   * Get a fork joined with current user role scopes
   * @returns
   */
  getUserScopes(user: User): Observable<string[]> {
    if (!user) {
      return of([]);
    }

    return this.getAllRoles().pipe(
      map((roles) => {
        const userRole = roles.find((role) => role.id === user.userRoleId);

        return userRole.scopes;
      })
    );
  }

  upsertUserEventsSubscriptions(user: User, subscriptions: CmEventSubscription[]) {
    return this.http.put<UserApiResponseData>(`users/${user.id}/subscriptions`, subscriptions);
  }
}
