import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'cm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl(),
    password: new UntypedFormControl(),
  });

  hide = true;
  errorMessage = null;
  hasError = false;

  constructor(private navigationService: NavigationService, private authService: AuthService, private matDialog: MatDialog) {
    this.loginForm.valueChanges.subscribe(() => (this.hasError = false));
  }

  submit() {
    const { email, password } = this.loginForm.value;

    this.authService
      .login(email, password)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.hasError = true;
          this.errorMessage = error.status === 401 ? 'Invalid login data' : 'An unknow error ocurred';

          throw error;
        })
      )
      .subscribe(() => {
        this.navigationService.navigateToDefault();
      });
  }
}
