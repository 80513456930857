import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationConstants } from 'src/app/shared/constants/validation.constants';
import { CustomFormValidators } from 'src/app/shared/utils/form-validator';

@Component({
  selector: 'cm-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent {
  formAttributes = {
    password: 'password',
    passwordConfirmation: 'passwordConfirmation',
  };

  formGroup: UntypedFormGroup;

  @Input() title = 'Define a tua password';
  @Input() disabled = false;

  @Output() submit = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  EqualToOtherControlValidator;

  submited = false;

  hasError = false;

  constructor() {
    const passwordControl = new UntypedFormControl(null, [
      Validators.minLength(ValidationConstants.PasswordMinLength),
      CustomFormValidators.PasswordStrengthValidator,
    ]);

    const passwordConfirmationControl = new UntypedFormControl(null, [CustomFormValidators.EqualToOtherControlValidator(passwordControl)]);

    this.formGroup = new UntypedFormGroup({
      [this.formAttributes.password]: passwordControl,
      [this.formAttributes.passwordConfirmation]: passwordConfirmationControl,
    });

    this.formGroup.valueChanges.subscribe(() => (this.hasError = false));
  }

  confirmClicked() {
    this.submited = true;

    if (this.formGroup.get(this.formAttributes.password).value !== this.formGroup.get(this.formAttributes.passwordConfirmation).value) {
      return;
    }

    this.submit.emit(this.formGroup.get(this.formAttributes.password).value);
  }

  cancelClicked() {
    this.cancel.emit();
  }

  closeClicked() {
    this.close.emit();
  }
}
