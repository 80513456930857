import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from './services/auth.http-interceptor';
import { RouteAuthGuardService } from './services/route-auth-guard.service';
import { AuthService } from './services/auth.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { LoginComponent } from './components/login/login.component';
import { MatInputModule } from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MatButtonModule } from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import { MyUserSettingsComponent } from './components/my-user-settings/my-user-settings.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserActivationComponent } from './components/user-activation/user-activation.component';
import { PasswordChangePopupComponent } from './components/password-change-popup/password-change-popup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '../shared/shared.module';

const materialModules = [
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatCheckboxModule,
  MatCardModule,
];

@NgModule({
  declarations: [LoginComponent, MyUserSettingsComponent, PasswordChangeComponent, UserActivationComponent, PasswordChangePopupComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, ...materialModules, SharedModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true, deps: [AuthService, Router] },
    RouteAuthGuardService,
    AuthService,
    CookieService,
  ],
})
export class AuthenticationModule {
  constructor() {}
}
