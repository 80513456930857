export type AttachmentResource = {
  id?: number;
  url?: string;
  filesize?: number;
  description?: string;
  validity?: string;
  filename?: string;
  action?: string;
};

export enum AttachmentAction {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

export class Attachment {
  id?: number;
  file?: File;
  url?: string;
  filesize: number;
  description?: string;
  validity?: Date;
  filename: string;
  action?: AttachmentAction;

  constructor(attachment: Attachment | AttachmentResource) {
    this.id = attachment.id;
    this.file = (attachment as Attachment).file;
    this.url = (attachment as AttachmentResource).url;

    this.description = attachment.description;
    this.validity = attachment.validity ? new Date(attachment.validity) : null;
    this.filename = attachment.filename || (attachment as Attachment).file?.name;
    this.filesize = attachment.filesize || (attachment as Attachment).file?.size;
    this.action = (attachment as Attachment).action;
  }

  toRequestModel(): AttachmentResource {
    return {
      id: this.id,
      url: this.url,
      filesize: this.filesize,
      description: this.description,
      validity: this.validity?.toISOString(),
      filename: this.filename,
      action: AttachmentAction[this.action],
    };
  }
}
