<div class="flex-vertical flex-centered">
  <img src="assets/logo.png" />

  <form [formGroup]="loginForm">
    <mat-form-field appearance="fill">
      <mat-label>Utilizador</mat-label>
      <input formControlName="email" matInput />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Insira a sua password</mat-label>
      <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Esconder password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    </mat-form-field>

    <div *ngIf="hasError" class="error-message">{{ errorMessage }}</div>

    <button mat-button color="primary" (click)="submit()">Entrar</button>
  </form>
</div>
