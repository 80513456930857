import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { EditionComponent } from '../models/edition-component.interface';

@Injectable({
  providedIn: 'root',
})
export class OpenModalNavigationGuard  {
  get hasUnsavedDialogs() {
    const unsavedDialogs = this.matDialog.openDialogs
      .map((dialog) => dialog.componentInstance)
      .filter((component: EditionComponent) => component.hasUnsavedChanges);

    return unsavedDialogs.length > 0;
  }

  constructor(private readonly matDialog: MatDialog) {
    window.addEventListener('popstate', (event) => {
      const unsavedDialogs = this.matDialog.openDialogs
        .map((dialog) => dialog.componentInstance)
        .filter((component: EditionComponent) => component.hasUnsavedChanges);

      if (unsavedDialogs.length) {
        event.preventDefault();
      } else {
        this.matDialog.closeAll();
      }
    });

    window.addEventListener(
      'beforeunload',
      (event) => {
        if (this.hasUnsavedDialogs) {
          event.preventDefault();
          return (event.returnValue = 'Tem a certeza que deseja sair e descartar possíveis alterações?');
        } else {
          this.matDialog.closeAll();
        }
      },
      { capture: true }
    );
  }

  canActivate(): boolean {
    const canActivate = !this.hasUnsavedDialogs;

    if (canActivate) {
      this.matDialog.closeAll();
    }

    return canActivate;
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }
}
