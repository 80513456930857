import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPseudoCheckboxModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DateTimeControlComponent } from './components/date-time-control/date-time-control.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EmptyListPlaceholderComponent } from './components/empty-list-placeholder/empty-list-placeholder.component';
import { PersonsListComponent } from './components/persons-list/persons-list.component';
import { PersonDetailsComponent } from './components/person-details/person-details.component';
import { PersonsVisualizerComponent } from './components/persons-visualizer/persons-visualizer.component';
import { PersonCardComponent } from './components/person-card/person-card.component';
import { IfAnyScopesDirective } from './directives/if-any-scopes.directive';
import { IfAllScopesDirective } from './directives/if-all-scopes.directive';
import { IfNotScopesDirective } from './directives/if-not-scopes.directive';
import { EnabledAnyScopesDirective } from './directives/enabled-any-scopes.directive';
import { EnabledAllScopesDirective } from './directives/enabled-all-scopes.directive';
import { EnabledNotScopesDirective } from './directives/enabled-not-scopes.directive';
import { FileDropModule } from './file-drop/file-drop.module';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AreaMenuComponent } from './components/area-menu/area-menu.component';
import { AttachmentsModule } from './attachments/attachments.module';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS, NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { MatDividerModule } from '@angular/material/divider';
import { FormPageSubmissionComponent } from './components/form-page-submission/form-page-submission.component';
import { ExportCsvDialogComponent } from './components/export-csv-dialog/export-csv-dialog.component';
import { DynamicListComponent } from './components/dynamic-list/dynamic-list.component';
import { PayableItemEditionComponent } from './components/payable-item-edition/payable-item-edition.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MomentModule } from 'ngx-moment';
import { QrCodeScannerComponent } from './components/qr-code-scanner/qr-code-scanner.component';
import { LOAD_WASM, NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { LastUpdateInfoComponent } from './components/last-update-info/last-update-info.component';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';

LOAD_WASM().subscribe();

const materialModules = [
  MatExpansionModule,
  MatSidenavModule,
  MatBadgeModule,
  MatIconModule,
  MatPseudoCheckboxModule,
  MatSelectModule,
  MatCardModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatInputModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonModule,
  MatBadgeModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatMenuModule,
  MatDialogModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatButtonToggleModule,
  MatDividerModule,
  MatTabsModule,
];

const angularModules = [CommonModule, FormsModule, ReactiveFormsModule];

@NgModule({
  declarations: [
    PersonsListComponent,
    PersonDetailsComponent,
    PersonCardComponent,
    PersonsVisualizerComponent,
    BreadcrumbsComponent,
    DateTimeControlComponent,
    EmptyListPlaceholderComponent,
    IfAnyScopesDirective,
    IfAllScopesDirective,
    IfNotScopesDirective,
    EnabledAnyScopesDirective,
    EnabledAllScopesDirective,
    EnabledNotScopesDirective,
    AreaMenuComponent,
    CountrySelectComponent,
    FormPageSubmissionComponent,
    ExportCsvDialogComponent,
    DynamicListComponent,
    PayableItemEditionComponent,
    QrCodeScannerComponent,
    LastUpdateInfoComponent,
    AlertBoxComponent,
  ],
  imports: [
    ...angularModules,
    ...materialModules,
    ScrollingModule,
    DragDropModule,
    TableVirtualScrollModule,
    FileDropModule,
    AttachmentsModule,
    MaterialFileInputModule,
    NgxMatIntlTelInputComponent,
    NgxMatColorPickerModule,
    MomentModule,
    NgxScannerQrcodeModule,
  ],
  exports: [
    ...materialModules,
    ...angularModules,
    NgxMatColorPickerModule,
    MaterialFileInputModule,
    PersonsListComponent,
    PersonDetailsComponent,
    BreadcrumbsComponent,
    DateTimeControlComponent,
    EmptyListPlaceholderComponent,
    CountrySelectComponent,
    FormPageSubmissionComponent,
    IfAnyScopesDirective,
    IfAllScopesDirective,
    IfNotScopesDirective,
    EnabledAnyScopesDirective,
    EnabledAllScopesDirective,
    EnabledNotScopesDirective,
    DynamicListComponent,
    PayableItemEditionComponent,
    QrCodeScannerComponent,
    LastUpdateInfoComponent,
    AlertBoxComponent,
    MomentModule,
    NgxMatIntlTelInputComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { closeOnNavigation: false } },
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
  ],
})
export class SharedModule {}
