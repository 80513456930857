import { filter } from 'rxjs/operators';

import { Component } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRouteSnapshot, Route } from '@angular/router';
import { AuthService } from './authentication/services/auth.service';
import { SwPush, SwUpdate, VersionEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewVersionNotificationComponent } from './pwa/new-version-notification/new-version-notification.component';
import { ACCEPTED_NON_AUTH_ROUTES } from './shared/constants/global.constants';

const VAPID_PUBLIC_KEY = 'BCZW0p58nS2AS7NJIPn09qImvQvYsYjbCVOfnWicGMD5GmYnn2WaMdXA7MvSaAAxHSTAw5cXXwMUYL4nEzmL_Rw';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'club-manager';

  isAuthenticated = false;
  onNonAuthenticatedRoutes = true;
  currentArea: string = null;
  onRoot = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private snackBar: MatSnackBar,
  ) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(filter((event: VersionEvent) => event.type === 'VERSION_READY'))
        .subscribe(() => {
          this.snackBar.openFromComponent(NewVersionNotificationComponent, {
            panelClass: 'mat-mdc-snack-bar-container--success',
            verticalPosition: 'top',
          });
        });
    }

    if (this.swPush.isEnabled && Notification.permission === 'granted') {
      this.swPush
        .requestSubscription({
          serverPublicKey: VAPID_PUBLIC_KEY,
        })
        .then((sub) => {
          this.authService.subscribeToPushNotifications(sub).subscribe(() => {});

          this.swPush.notificationClicks.subscribe((message) => console.log(message));
        })
        .catch((err) => console.error('Could not subscribe to notifications', err));
    }

    this.authService.authenticatedStateChange.subscribe((authenticated) => {
      this.isAuthenticated = authenticated;
    });

    this.router.events.pipe(filter((e) => e instanceof RoutesRecognized)).subscribe((event: RoutesRecognized) => {
      this.onRoot = this.getLastRouteChildCount(event.state.root) === 1;

      const activatedPath = this.getLastRouteChildConfig(event.state.root)?.path;
      this.onNonAuthenticatedRoutes = !this.onRoot && ACCEPTED_NON_AUTH_ROUTES.some((acceptedUrl) => acceptedUrl === activatedPath);
    });
  }

  private getLastRouteChildConfig(activatedRouteSnapshot: ActivatedRouteSnapshot): Route {
    return activatedRouteSnapshot.firstChild
      ? this.getLastRouteChildConfig(activatedRouteSnapshot.firstChild)
      : activatedRouteSnapshot.routeConfig;
  }

  private getLastRouteChildCount(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return activatedRouteSnapshot.firstChild ? this.getLastRouteChildCount(activatedRouteSnapshot.firstChild) + 1 : 0;
  }
}
