// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DocumentTemplate, DocumentTemplateApiData } from 'src/app/settings/documents/document-template.model';
import { DataRawType, DataType } from 'src/app/shared/constants/global.constants';
import { CustomFormFieldLabel } from 'src/app/shared/constants/forms.constants';

export type EntityField = {
  name: string;
  type: DataType;
  rawType: DataRawType;
  label?: CustomFormFieldLabel;
};

export type EntityFields = EntityField[];

export type EntitiesFields = {
  [key: string]: EntityField[];
};

@Injectable()
export class ConfigurationsService {
  private entitiesFields: EntitiesFields;

  constructor(private http: HttpClient, private router: Router) {}

  getDocuments(): Observable<DocumentTemplate[]> {
    return this.getConfiguration('templates').pipe(map((documents) => documents.map((document) => new DocumentTemplate(document))));
  }

  saveDocument(document: DocumentTemplate) {
    const request = document.id
      ? this.http.put<DocumentTemplateApiData>(`configuration/templates/${document.id}`, document)
      : this.http.post<DocumentTemplateApiData>('configuration/templates', document);

    return request.pipe(map((template) => new DocumentTemplate(template)));
  }

  deleteDocument(document: DocumentTemplate) {
    return this.http.delete(`configuration/templates/${document.id}`);
  }

  getEntitiesFields() {
    return this.entitiesFields ? of(this.entitiesFields) : this.fetchEntitiesFields();
  }

  private fetchEntitiesFields() {
    return this.http.get<EntitiesFields>(`configuration/entities-fields`).pipe(tap((fields) => (this.entitiesFields = fields)));
  }

  private getConfiguration(name: string): Observable<any> {
    return this.http.get(`configuration/${name}`);
  }
}
