import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationBehaviorOptions,
  NavigationEnd,
  NavigationExtras,
  Route,
  Router,
  UrlTree,
} from '@angular/router';
import { merge } from 'rxjs';
import { filter, map, pairwise, take } from 'rxjs/operators';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { PermissionsService } from './permissions.service';
import { ACCEPTED_NON_AUTH_ROUTES } from '../constants/global.constants';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private previousUrl: string;
  private currentUrl: string;

  get canNavigateBack() {
    return !!this.previousUrl;
  }

  constructor(private authService: AuthService, private permissionsService: PermissionsService, private router: Router) {}

  load() {
    this.previousUrl = this.router.routerState.snapshot.url;

    this.authService.userChange
      .pipe(
        pairwise(),
        filter(([prev, current]) => prev && !current)
      )
      .subscribe(() => {
        const onRoot = this.getLastRouteChildCount(this.router.routerState.snapshot.root) === 1;
        const activatedPath = this.getLastRouteChildConfig(this.router.routerState.snapshot.root)?.path;
        const onNonAuthenticatedRoutes = !onRoot && ACCEPTED_NON_AUTH_ROUTES.some((acceptedUrl) => acceptedUrl === activatedPath);

        if (!onNonAuthenticatedRoutes) {
          this.router.navigate(['login']);
        }
      });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = this.router.routerState.snapshot.url;
    });

    this.authService.checkAuth().subscribe((logged) => {
      const onRoot = this.getLastRouteChildCount(this.router.routerState.snapshot.root) === 1;
      const activatedPath = this.getLastRouteChildConfig(this.router.routerState.snapshot.root)?.path;
      const onNonAuthenticatedRoutes = !onRoot && ACCEPTED_NON_AUTH_ROUTES.some((acceptedUrl) => acceptedUrl === activatedPath);

      if (logged && onNonAuthenticatedRoutes) {
        this.authService.logout();
      } else if (!logged && !onNonAuthenticatedRoutes) {
        this.router.navigate(['login']);
      }
    });
  }

  /**
   * Navigate to a given path array
   * @param commands
   * @param extras
   */
  navigate(commands: any[], extras?: NavigationExtras) {
    return this.router.navigate(commands, extras);
  }

  navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions) {
    return this.router.navigateByUrl(url, extras);
  }

  navigateToPrevious(fallbackRoute: string, extras?: NavigationExtras) {
    return this.previousUrl ? this.router.navigateByUrl(this.previousUrl, extras) : this.router.navigateByUrl(fallbackRoute);
  }

  navigateToDefault() {
    merge(
      this.permissionsService.userHasAnyScope(['membership.*']).pipe(map((has) => (has ? ['members'] : null))),
      this.permissionsService.userHasAnyScope(['sportactivity.*']).pipe(map((has) => (has ? ['sport-activity'] : null))),
      this.permissionsService.userHasAnyScope(['settings.*']).pipe(map((has) => (has ? ['settings'] : null))),
      this.permissionsService.userHasAnyScope(['ticketing.*']).pipe(map((has) => (has ? ['ticketing'] : null))),
      this.permissionsService.userHasAnyScope(['finances.*']).pipe(map((has) => (has ? ['finances'] : null)))
    )
      .pipe(
        filter((route) => Boolean(route)),
        take(1)
      )
      .subscribe((route) => {
        this.navigate(route);
      });
  }

  private getLastRouteChildConfig(activatedRouteSnapshot: ActivatedRouteSnapshot): Route {
    return activatedRouteSnapshot.firstChild
      ? this.getLastRouteChildConfig(activatedRouteSnapshot.firstChild)
      : activatedRouteSnapshot.routeConfig;
  }

  private getLastRouteChildCount(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return activatedRouteSnapshot.firstChild ? this.getLastRouteChildCount(activatedRouteSnapshot.firstChild) + 1 : 0;
  }
}
