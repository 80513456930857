import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ValidationConstants } from 'src/app/shared/constants/validation.constants';


export class CustomFormValidators {

  static PasswordStrengthValidator: ValidatorFn = (control: AbstractControl) => {
    const value = (control.value as string || '').trim();

    let errors: ValidationErrors = {};

    const upperCaseRegex = /[A-Z]{1}/g;
    const digitsCaseRegex = /[0-9]{1}/g;

    const upperCharsCount = (value.match(upperCaseRegex) || []).length;
    const digitsCount = (value.match(digitsCaseRegex) || []).length;
    const lettersCount = value.length - digitsCount;

    if (upperCharsCount < ValidationConstants.PasswordMinUpperCharsCount) {
      errors = {
        ...errors,
        minupperchars: ValidationConstants.PasswordMinUpperCharsCount,
      };
    }

    if (digitsCount < ValidationConstants.PasswordMinDigitsCount) {
      errors = {
        ...errors,
        mindigits: ValidationConstants.PasswordMinDigitsCount,
      };
    }

    if (lettersCount < ValidationConstants.PasswordMinLettersCount) {
      errors = {
        ...errors,
        minletters: ValidationConstants.PasswordMinLettersCount,
      };
    }

    return Object.keys(errors).length ? errors : null;
  };

  
  static EqualToOtherControlValidator = (controlToCompare: AbstractControl): ValidatorFn => (control: AbstractControl) => {
    const value = control.value;
    const valueToCompare = controlToCompare.value;

    if (value !== valueToCompare) {
      return {
        compare: valueToCompare,
      };
    }

    return null;
  };

  static getOptionsByValue = (options: { value: string | number; text: string }[], value: string | number) => {
    return options.find((option) => option.value === value);
  };
}