import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';

import { MomentModule } from 'ngx-moment';

import { AppComponent } from './app.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { NgUnsubscriberComponent } from './shared/components/ng-unsubscriber/ng-unsubscriber.component';
import { ApiModule } from './shared/api/api.module';
import { LoginComponent } from './authentication/components/login/login.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { RouteAuthGuardService } from './authentication/services/route-auth-guard.service';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MyUserSettingsComponent } from './authentication/components/my-user-settings/my-user-settings.component';
import { CommonModule } from '@angular/common';
import { UserActivationComponent } from './authentication/components/user-activation/user-activation.component';
import { ConfigLoaderService } from './shared/services/config-loader.service';
import { SharedModule } from './shared/shared.module';
import { PermissionGuardService } from './authentication/services/permission-guard.service';
import { NavigationService } from './shared/services/navigation.service';
import { OpenModalNavigationGuard } from './shared/utils/open-modal-navigation-guard.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConfigurationsModule } from './configurations/configurations.module';

class CmRouteReuseStrategy implements RouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.shouldReuse || false;
  }

  store(route: ActivatedRouteSnapshot, handle: {}): void {
    if (route.data.shouldReuse) {
      this.handlers[route.routeConfig.path] = handle;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
  }

  retrieve(route: ActivatedRouteSnapshot): {} {
    if (!route.routeConfig) return null;
    return this.handlers[route.routeConfig.path];
  }

  shouldReuseRoute(nextRoute: ActivatedRouteSnapshot, currentRoute: ActivatedRouteSnapshot): boolean {
    return nextRoute.data.shouldReuse || false;
  }
}

class CmHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { velocity: 0.4, threshold: 20 }, // override default settings
  };
}

const routes: Routes = [
  {
    path: '',
    canActivateChild: [OpenModalNavigationGuard],
    children: [
      {
        path: 'events',
        loadChildren: () => import('./events/events.module').then((m) => m.EventsModule),
        canActivate: [RouteAuthGuardService],
      },
      {
        path: 'merchandising',
        loadChildren: () => import('./merchandising/merchandising.module').then((m) => m.MerchandisingModule),
        canActivate: [RouteAuthGuardService],
      },
      {
        path: 'members',
        loadChildren: () => import('./membership/membership.module').then((m) => m.MembershipModule),
        canActivate: [RouteAuthGuardService, PermissionGuardService],
        data: {
          scopes: ['membership.*'],
        },
      },
      {
        path: 'sport-activity',
        loadChildren: () => import('./sport-activity/sport-activity.module').then((m) => m.SportActivityModule),
        canActivate: [RouteAuthGuardService, PermissionGuardService],
        data: {
          scopes: ['sportactivity.*'],
        },
      },
      {
        path: 'finances',
        loadChildren: () => import('./finances/finances.module').then((m) => m.FinancesModule),
        canActivate: [RouteAuthGuardService, PermissionGuardService],
        data: {
          scopes: ['finances.*'],
        },
      },
      {
        path: 'ticketing',
        loadChildren: () => import('./ticketing/ticketing.module').then((m) => m.TicketingModule),
        canActivate: [RouteAuthGuardService, PermissionGuardService],
        data: {
          scopes: ['ticketing.*'],
        },
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [RouteAuthGuardService, PermissionGuardService],
        data: {
          scopes: ['settings.*'],
        },
      },
      {
        path: 'me',
        pathMatch: 'full',
        component: MyUserSettingsComponent,
        canActivate: [RouteAuthGuardService],
        data: { title: 'Dados e configurações pessoais' },
      },
      {
        path: 'login',
        pathMatch: 'full',
        component: LoginComponent,
      },
      {
        path: 'activate',
        component: UserActivationComponent,
      },
      {
        path: '*',
        component: LoginComponent,
      },
      {
        path: '',
        component: LoginComponent,
      },
    ],
  },
];

const materialModules = [MatCardModule, MatIconModule, MatButtonModule, MatMenuModule, MatDividerModule];

function initConfig(configLoaderService: ConfigLoaderService) {
  return () => configLoaderService.load();
}

function initNavigationService(navigationService: NavigationService) {
  return () => navigationService.load();
}

@NgModule({
  declarations: [AppComponent, MainMenuComponent, NgUnsubscriberComponent, ConfirmationDialogComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,

    ...materialModules,

    MomentModule,
    BrowserAnimationsModule,

    ApiModule,
    AuthenticationModule,
    ConfigurationsModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [ConfigLoaderService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initNavigationService, deps: [NavigationService], multi: true },
    { provide: RouteReuseStrategy, useClass: CmRouteReuseStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CmHammerConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
