export enum DocumentTemplateFieldRenderType {
  TEXT = 'text',
  IMAGE = 'image',
  QR_CODE = 'qrcode',
  BOOLEAN = 'boolean',
}

export enum DocumentTemplateFieldTextAlignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum DocumentTemplateFieldTextStyle {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
}

export class DocumentTemplateFieldObjectSize {
  width: number;
  height: number;
}

export class DocumentTemplateMargins {
  vertical: string;
  horizontal: string;
}

export class DocumentTemplateFieldObjectPosition {
  x: number;
  y: number;
}

type DocumentTemplateFieldApiData = {
  type: DocumentTemplateFieldRenderType;
  content: string;
  position: DocumentTemplateFieldObjectPosition;
  multiline?: boolean;
  static_value?: string;
  size: DocumentTemplateFieldObjectSize;
  style: { [key: string]: number | string };
};

export type DocumentTemplateFieldToMap = {
  name: string;
  description: string;
  type: DocumentTemplateFieldRenderType;
};

export const DOCUMENT_LAYOUT_DEFAULT_FIELD_CONFIG = {
  content: null,
  type: DocumentTemplateFieldRenderType.TEXT,
  position: {
    x: 0.05,
    y: 0.05,
  },
  size: {
    width: 0.3,
    height: 0.3,
  },
  style: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: 16,
  },
};

type DocumentPropertiesSize = {
  width: string;
  height: string;
};

type DocumentPropertiesMargins = {
  vertical: string;
  horizontal: string;
};

export type DocumentProperties = {
  content: string;
  size: DocumentPropertiesSize;
  margins: DocumentPropertiesMargins;
};

export enum DocumentTemplateImageSize {
  COVER = 'cover',
  FIT = 'contain',
  STRETCH = '100% 100%',
}

export enum DocumentTemplateTextAdjust {
  CROP = 'crop',
  INITIALS_LEFT = 'initials_left',
  INITIALS_MIDDLE = 'initials_middle',
  INITIALS_RIGHT = 'initials_right',
  ADJUST_FONT_SIZE = 'adjust_font_size',
}

export const DEFAULT_LAYOUT_DEFINITION: DocumentTemplateDefinitionApiData = {
  size: {
    width: 242.65,
    height: 153.01,
  },
  margins: {
    vertical: '10mm',
    horizontal: '10mm',
  },
  style: {
    fontFamily: 'Arial',
    fontSize: 16,
    backgroundUrl: '',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    backgroundSize: DocumentTemplateImageSize.COVER,
  },
  blocks: [],
};

const DEFAULT_LAYOUT: DocumentTemplateApiResponseData = {
  name: '',
  definition: DEFAULT_LAYOUT_DEFINITION,
};

export class DocumentTemplateField {
  type: DocumentTemplateFieldRenderType;
  content: string;
  position: DocumentTemplateFieldObjectPosition;
  multiline: boolean;
  style: { [key: string]: number | string | DocumentTemplateImageSize };
  size: DocumentTemplateFieldObjectSize;

  constructor(obj: DocumentTemplateField | DocumentTemplateFieldApiData = DOCUMENT_LAYOUT_DEFAULT_FIELD_CONFIG) {
    this.content = obj.content;
    this.position = {
      ...obj.position,
    };
    this.content = obj.content;
    this.multiline = obj.multiline;
    this.size = {
      ...obj.size,
    };
    this.type = obj.type || DocumentTemplateFieldRenderType.TEXT;
    this.style = {
      ...DOCUMENT_LAYOUT_DEFAULT_FIELD_CONFIG.style,
      ...(obj.style || {}),
    };
  }

  toRequestModel(): DocumentTemplateFieldApiData {
    return {
      content: this.content,
      position: {
        x: this.position.x,
        y: this.position.y,
      },
      size: {
        width: this.size.width,
        height: this.size.height,
      },
      type: this.type,
      style: this.style,
    };
  }
}

type DocumentTemplateDefinitionApiData = {
  size: DocumentTemplateFieldObjectSize;
  margins: DocumentTemplateMargins;
  content?: string;
  style: { [key: string]: number | string };
  blocks: DocumentTemplateFieldApiData[];
};

export type DocumentTemplateDefinitionApiRequestData = DocumentTemplateDefinitionApiData;
export type DocumentTemplateDefinitionApiResponseData = DocumentTemplateDefinitionApiData;

export class DocumentTemplateDefinition {
  size: DocumentTemplateFieldObjectSize;
  margins: DocumentTemplateMargins;
  content?: string;
  backgroundUrl?: string;
  backgroundColor?: string;
  backgroundSize?: DocumentTemplateImageSize;
  style?: { [key: string]: number | string };
  blocks?: DocumentTemplateField[];

  constructor(obj: DocumentTemplateDefinition | DocumentTemplateDefinitionApiResponseData = DEFAULT_LAYOUT_DEFINITION) {
    this.size = obj.size || DEFAULT_LAYOUT_DEFINITION.size;
    this.margins = obj.margins || DEFAULT_LAYOUT_DEFINITION.margins;
    this.content = obj.content;
    this.style = obj.style || {};
    this.blocks = (obj.blocks || []).map((block) => new DocumentTemplateField(block));
  }

  toRequestModel(): DocumentTemplateDefinitionApiRequestData {
    return {
      size: {
        width: this.size.width,
        height: this.size.height,
      },
      margins: {
        vertical: this.margins.vertical,
        horizontal: this.margins.horizontal,
      },
      content: this.content,
      style: this.style,
      blocks: this.blocks.map((block) => block.toRequestModel()),
    };
  }
}

export interface DocumentTemplateApiData {
  id?: string;
  name: string;
  definition: DocumentTemplateDefinitionApiData;
}

export type DocumentTemplateApiRequestData = DocumentTemplateApiData;
export type DocumentTemplateApiResponseData = DocumentTemplateApiData;

export class DocumentTemplate {
  id?: string;
  name?: string;
  type: string;
  definition: DocumentTemplateDefinition;

  constructor(obj: DocumentTemplate | DocumentTemplateApiResponseData = DEFAULT_LAYOUT) {
    this.id = obj.id;
    this.name = obj.name;
    this.definition = new DocumentTemplateDefinition(obj.definition);
  }

  toRequestModel(): DocumentTemplateApiRequestData {
    return {
      id: this.id,
      name: this.name,
      definition: this.definition.toRequestModel(),
    };
  }

  static getBackgroundCss(size: DocumentTemplateImageSize | string) {
    switch (size) {
      case DocumentTemplateImageSize.COVER:
        return 'cover';
      case DocumentTemplateImageSize.FIT:
        return 'contain';
      case DocumentTemplateImageSize.STRETCH:
        return '100% 100%';
      default:
        return '';
    }
  }
}
