import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../models/user.model';
import { AuthService, CmEventChannelSubscription } from '../../services/auth.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { PasswordChangePopupComponent } from '../password-change-popup/password-change-popup.component';
import { CmEventSubscription, UsersService } from 'src/app/settings/users/users.service';
import { SwPush } from '@angular/service-worker';
import { CmEvent } from '../../models/event.model';
import { ChannelType } from 'src/app/shared/constants/global.constants';

type CmEventDomain = {
  key: string;
  title: string;
};

const VAPID_PUBLIC_KEY = 'BCZW0p58nS2AS7NJIPn09qImvQvYsYjbCVOfnWicGMD5GmYnn2WaMdXA7MvSaAAxHSTAw5cXXwMUYL4nEzmL_Rw';

@Component({
  selector: 'cm-my-user-settings',
  templateUrl: './my-user-settings.component.html',
  styleUrls: ['./my-user-settings.component.scss'],
})
export class MyUserSettingsComponent implements OnInit {
  ChannelType = ChannelType;

  formAttributes = {
    name: 'name',
    email: 'email',
    msisdn: 'msisdn',
  };

  formGroup = new UntypedFormGroup({
    [this.formAttributes.name]: new UntypedFormControl(),
    [this.formAttributes.email]: new UntypedFormControl(),
    [this.formAttributes.msisdn]: new UntypedFormControl(),
  });

  user: User;

  isLoading = false;

  notificationsEnabled = Notification.permission === 'granted';

  eventSubscriptionsChanged = false;

  events: CmEvent[];

  subscribedEvents: CmEventSubscription[] = [];

  domains: CmEventDomain[] = [
    {
      key: 'membership',
      title: 'Gestão de Sócios',
    },
    {
      key: 'sportactivity',
      title: 'Atividade Desportiva',
    },
    {
      key: 'ticketing',
      title: 'Eventos e Bilheteira',
    },
    {
      key: 'finances',
      title: 'Pagamentos e Finanças',
    },
  ];

  constructor(
    private authService: AuthService,
    private userService: UsersService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private swPush: SwPush
  ) {
    this.authService.getCurrentUser().subscribe((user) => {
      this.user = user;
      this.resetFormValues();
    });

    this.authService.getAllEvents().subscribe((events) => {
      this.events = Object.keys(events).reduce((prev, domain) => {
        return [
          ...prev,
          ...events[domain].map((event) => {
            return {
              key: `${domain}.${event.key}`,
              title: event.title,
              description: event.title,
            };
          }),
        ];
      }, []);
    });

    this.getUserEventsSubscriptions();
  }

  ngOnInit(): void {}

  getDomainEvents(domain: string) {
    return this.events ? this.events.filter((event) => event.key.includes(`${domain}.`)) : [];
  }

  userHasChannelSubscription(eventKey, channelType: ChannelType) {
    return this.subscribedEvents.find((e) => e.key === eventKey)?.channels.find((c) => c === channelType);
  }

  toggleNotification(eventKey: string, channelType: ChannelType, checked: boolean) {
    this.eventSubscriptionsChanged = true;

    if (checked && channelType === ChannelType.PUSH && !this.notificationsEnabled) {
      this.requestSubscriptionToNotifications();
    }

    if (checked) {
      this.addEventChannelSubscription({
        event: eventKey,
        channel: channelType,
      });
    } else {
      this.removeEventChannelSubscription({
        event: eventKey,
        channel: channelType,
      });
    }
  }

  cancel() {
    this.subscribedEvents = [];
    this.getUserEventsSubscriptions();
    this.formGroup.reset();
    this.resetFormValues();
  }

  save() {
    this.isLoading = true;

    this.userService.upsertUserEventsSubscriptions(this.user, this.subscribedEvents).subscribe((e) => console.log(e));

    this.userService
      .upsertUser(
        new User({
          ...this.user,
          ...this.formGroup.value,
        })
      )
      .pipe(
        tap(() => {
          this.snackBar.open('User saved successfully', 'Close', {
            panelClass: 'mat-mdc-snack-bar-container--success',
            verticalPosition: 'top',
          });
        }),
        catchError(() => {
          const message = 'An error had ocurred';

          this.snackBar.open(message, 'Close', {
            panelClass: 'mat-mdc-snack-bar-container--error',
            verticalPosition: 'top',
          });

          return of(false);
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  resetFormValues() {
    this.formGroup.get(this.formAttributes.name).setValue(this.user?.name);
    this.formGroup.get(this.formAttributes.email).setValue(this.user?.email);
    this.formGroup.get(this.formAttributes.msisdn).setValue(this.user?.msisdn);
  }

  changePassword() {
    this.dialog.open(PasswordChangePopupComponent, { data: { userId: this.user.id } });
  }

  private getUserEventsSubscriptions() {
    this.authService.getEventSubscriptions().subscribe((events) => {
      events.forEach((event: CmEventChannelSubscription) => {
        this.addEventChannelSubscription(event);
      });

      if (this.subscribedEvents.some(event => event.channels.includes(ChannelType.PUSH)) && !this.notificationsEnabled) {
        this.requestSubscriptionToNotifications();
      }
    });
  }

  private addEventChannelSubscription(event: CmEventChannelSubscription) {
    let subscribed = this.subscribedEvents.find((e) => e.key === event.event);

    if (!subscribed) {
      subscribed = {
        key: event.event,
        channels: [],
      };

      this.subscribedEvents.push(subscribed);
    }

    subscribed.channels.push(event.channel);
  }

  private removeEventChannelSubscription(event: CmEventChannelSubscription) {
    const keyIndex = this.subscribedEvents.findIndex((e) => e.key === event.event);

    if (keyIndex >= 0) {
      const channelIndex = this.subscribedEvents[keyIndex].channels.indexOf(event.channel);

      if (channelIndex >= 0) {
        this.subscribedEvents[keyIndex].channels.splice(channelIndex, 1);
      }
    }
  }

  private requestSubscriptionToNotifications() {
    if (Notification.permission === 'denied') {
      const message = 'O seu browser não permite pedir a subcsrição de notificações. Por favor altere as suas definições e tente novamente';

      this.snackBar.open(message, 'Close', {
        panelClass: 'mat-mdc-snack-bar-container--error',
        verticalPosition: 'top',
      });
    } else {
      this.subscribeToNotifications();
    }
  }

  private subscribeToNotifications() {
    Notification.requestPermission().then((result) => {
      this.swPush
        .requestSubscription({
          serverPublicKey: VAPID_PUBLIC_KEY,
        })
        .then((sub) => {
          this.authService.subscribeToPushNotifications(sub).subscribe((subscribed) => {
            this.notificationsEnabled = subscribed;
          });
        })
        .catch((error) => {
          console.log(error);
          const message = 'Could not subscribe to notifications';

          this.snackBar.open(message, 'Close', {
            panelClass: 'mat-mdc-snack-bar-container--error',
            verticalPosition: 'top',
          });
        });
    });
  }
}
