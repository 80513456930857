import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FileDropZoneDirective } from './file-drop-zone.directive';
import { PictureCaptureComponent } from './components/picture-capture/picture-capture.component';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    FileDropZoneDirective,
    PictureCaptureComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    PictureCaptureComponent,
    FileDropZoneDirective,
    PictureCaptureComponent,
  ]
})
export class FileDropModule { }
