import { Injectable } from '@angular/core';
import { NavigationSwipeData } from '../types';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private navigationSwipeData$: BehaviorSubject<NavigationSwipeData> = new BehaviorSubject(null);

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        startWith(this.activatedRoute)
      )
      .subscribe(() => {
        this.navigationSwipeData$.next(null);
      });
  }

  setCurrentNavigationData(data: NavigationSwipeData) {
    this.navigationSwipeData$.next(data);
  }

  getCurrentNavigationDataStreamer() {
    return this.navigationSwipeData$.asObservable();
  }
}
