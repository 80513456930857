import { CmEntity, CmEntityApiData, CmEntityApiRequestData, CmEntityApiResponseData } from 'src/app/shared/models/cm-entity.model';

interface UserRoleApiData extends CmEntityApiData {
  name: string;
  scopes?: string[];
}

interface UserRoleRequestData extends UserRoleApiData, CmEntityApiRequestData {}
export interface UserRoleResponseData extends UserRoleApiData, CmEntityApiResponseData {}

export class UserRole extends CmEntity {
  name: string;
  scopes: string[];

  constructor(payload: UserRoleResponseData) {
    super(payload);

    this.name = payload.name;
    this.scopes = payload.scopes || [];
  }

  toRequestModel(): UserRoleRequestData {
    return {
      ...super.toRequestModel(),
      name: this.name,
      scopes: this.scopes,
    };
  }
}
