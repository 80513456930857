<div class="padding-main-container padding-main-container--no-scroll full-size">
  <h2 class="text-centered">{{ title }}</h2>
  <div [formGroup]="formGroup" class="controls-container">
    <mat-form-field appearance="outline" floatLabel="never" class="password">
      <mat-label>Password</mat-label>
      <input matInput type="password" [formControlName]="formAttributes.password" [attr.disabled]="disabled ? '' : null" />

      <div *ngIf="formGroup.get('password').invalid && formGroup.get('password').dirty && formGroup.get('password').touched">
        <mat-error *ngIf="formGroup.get('password').errors['minlength']; else mindigits"
          >A password tem que ter pelo menos {{ formGroup.get('password').errors['minlength'].requiredLength }} caractéres</mat-error
        >

        <ng-template #mindigits>
          <mat-error *ngIf="formGroup.get('password').errors['mindigits']; else minletters"
            >A password tem que ter pelo menos {{ formGroup.get('password').errors['mindigits'] }} números</mat-error
          >
        </ng-template>

        <ng-template #minletters>
          <mat-error *ngIf="formGroup.get('password').errors['minletters']; else minupperchars"
            >A password tem que ter pelo menos {{ formGroup.get('password').errors['minletters'] }} letras</mat-error
          >
        </ng-template>

        <ng-template #minupperchars>
          <mat-error *ngIf="formGroup.get('password').errors['minupperchars']"
            >A password tem que ter pelo menos {{ formGroup.get('password').errors['minupperchars'] }} maiúsculas</mat-error
          >
        </ng-template>

        <ng-template #minupperchars>
          <mat-error *ngIf="formGroup.get('passwordConfirmation').errors['compare']">As passwords não coincidem</mat-error>
        </ng-template>
      </div>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="never" class="password-confirmation">
      <mat-label>Confirmar password</mat-label>
      <input matInput type="password" [formControlName]="formAttributes.passwordConfirmation" [attr.disabled]="disabled ? '' : null" />

      <div
        *ngIf="
          formGroup.get('passwordConfirmation').invalid &&
          formGroup.get('passwordConfirmation').dirty &&
          formGroup.get('passwordConfirmation').touched
        "
      >
        <mat-error *ngIf="formGroup.get('passwordConfirmation').errors['compare']">As password não coincidem</mat-error>
      </div>
    </mat-form-field>
  </div>

  <div [formGroup]="formGroup" class="buttons-container">
    <button [disabled]="disabled || formGroup.pristine" mat-raised-button color="primary" class="submit-btn" (click)="confirmClicked()">
      Confirmar
    </button>
    <button *ngIf="close && (disabled || submited && formGroup.pristine)" mat-raised-button class="submit-btn" (click)="closeClicked()">Fechar</button>
    <button *ngIf="!disabled && (!submited || formGroup.dirty)" mat-raised-button class="submit-btn" (click)="cancelClicked()">Cancelar</button>
  </div>
</div>
