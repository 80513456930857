<div class="full-size flex-centered" [ngClass]="{ minimized: minimized }">
  <div class="items-container">
    <div class="logo">
      <img src="assets/logo.png" />
    </div>

    <ng-container *ngFor="let item of menuItems">
      <mat-card
        appearance="outlined"
        *ifAllScopes="item.scopes"
        class="item"
        [routerLink]="item.routerLink"
        routerLinkActive="area-menu-item--active"
      >
        <div class="content">
          <div class="content-inside">
            <div class="item-title">{{ item.title }}</div>
            <div class="item-body">
              <mat-icon aria-hidden="false" aria-label="{{ item.title }" class="pointer">
                {{ item.icon }}
              </mat-icon>
            </div>
          </div>
        </div>
      </mat-card>
    </ng-container>

    <div class="authentication-group">
      <button
        *ngIf="!isOnline"
        mat-icon-button
        color="primary"
        aria-label="offline"
        matTooltip="Sem conexão ao servidor. Verifique a sua conexão à internet"
      >
        <mat-icon>cloud_off</mat-icon>
      </button>

      <button mat-fab color="primary" aria-label="user initials" class="user-initials" [matMenuTriggerFor]="authMenu">
        {{ userInitials }}
      </button>

      <mat-menu #authMenu="matMenu" backdropClass="auth-menu-panel">
        <div class="name-header">{{ user?.name }}</div>
        <mat-divider></mat-divider>

        <button mat-menu-item (click)="openMySettings()">
          <mat-icon>manage-accounts</mat-icon>
          <span>My Settings</span>
        </button>

        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
