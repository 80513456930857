import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'cm-password-change-popup',
  templateUrl: './password-change-popup.component.html',
  styleUrls: ['./password-change-popup.component.scss'],
})
export class PasswordChangePopupComponent implements OnInit {
  message: string;
  disableForm = false;

  constructor(
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<PasswordChangePopupComponent>
  ) {}

  ngOnInit(): void {}

  submit(password: string) {
    this.message = null;

    this.authService
      .changePassword(this.data.userId, password)
      .pipe(
        catchError((error) => {
          this.message = 'An error ocurred changing your password';

          throw error;
        }),
        finalize(() => {
          this.disableForm = true;
        })
      )
      .subscribe(() => {
        this.message = 'Password changed successfuly';
        this.disableForm = true;
      });
  }

  closePopup() {
    this.dialogRef.close();
  }
}
