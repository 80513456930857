import { Person } from '../models/person.model';

export class GlobalConstants {
  static CONFIRMATION_DIALOG_DEFAULTS = {
    okBtnText: 'Confirmar',
    cancelBtnText: 'Cancelar',
  };
}

export interface NavState {
  fromScroll?: number;
  toScroll?: number;
}

export type PersonFilterValue = {
  key?: string;
  value?: string;
};

export interface PersonsNavState extends NavState {
  searchText?: string;
  filters?: PersonFilterValue[];
}

export interface MembersNavState extends NavState {
  searchText?: string;
  regularized?: boolean;
}

export interface UsersNavState extends NavState {
  searchText?: string;
  regularized?: boolean;
}

export interface AgentsNavState extends NavState {
  searchText?: string;
  sport_id?: number;
}

export enum PersonsListItemActionType {
  OPEN,
  EDIT,
  DELETE,
  MOVE,
  CALL,
  SEND_SMS,
  SEND_EMAIL,
}

export type PersonsListItemAction = {
  actionType: PersonsListItemActionType;
  person: Person;
};

export type PersonsVisualizerComponentActionCallback = (action: PersonsListItemActionType, person: Person) => void;

export enum HammerSwipeAction {
  LEFT = 'swipeleft',
  RIGHT = 'swiperight',
}

export type MobileNavigator = {
  virtualKeyboard: any;
};

export enum Language {
  PT_PT = 'pt-PT',
  EN_US = 'en-US',
}

export const selectedLanguage = Language.PT_PT;

export enum ChannelType {
  PUSH = 'PUSH',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export const ACCEPTED_NON_AUTH_ROUTES = ['login', 'logout', 'activate'];

export enum DataRawType {
  TEXT = 'text',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
}

export enum DataType {
  TEXT = 'text',
  DATE = 'date',
  LIST = 'list',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  IMAGE = 'image',
}

export const DataTypeToRawTypeMap = {
  [DataType.TEXT]: DataRawType.TEXT,
  [DataType.DATE]: DataRawType.TEXT,
  [DataType.LIST]: DataRawType.TEXT,
  [DataType.BOOLEAN]: DataRawType.BOOLEAN,
  [DataType.NUMBER]: DataRawType.NUMBER,
  [DataType.IMAGE]: DataRawType.TEXT,
};
