// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, catchError, take, filter, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { User, UserApiResponseData } from '../models/user.model';
import { UserScope } from '../models/user-scope.model';
import { CmEvent } from '../models/event.model';
import { ChannelType } from 'src/app/shared/constants/global.constants';

export enum UserScopeMatch {
  ANY,
  ALL,
  NONE,
}

export type CmEventChannelSubscription = {
  event: string;
  channel: ChannelType;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authenticated;
  private userSubject$ = new BehaviorSubject<User>(null);
  private scopes$ = new BehaviorSubject<UserScope[]>(null);
  private events$ = new BehaviorSubject<CmEvent[]>(null);

  get userChange() {
    return this.userSubject$.asObservable();
  }

  get authenticatedStateChange() {
    return this.userSubject$.pipe(map((user) => Boolean(user)));
  }

  constructor(private http: HttpClient) {
    this.authenticatedStateChange
      .pipe(
        distinctUntilChanged(),
        filter((authenticated) => authenticated)
      )
      .subscribe(() => {
        this.updateScopes();
        this.updateEvents();
      });
  }

  getAllScopes() {
    return this.scopes$.pipe(
      filter((scopes) => !!scopes),
      take(1)
    );
  }

  getAllEvents() {
    return this.events$.pipe(
      filter((events) => !!events),
      take(1)
    );
  }

  updateScopes() {
    this.fillUserScopes().subscribe((scopes) => this.scopes$.next(scopes));
  }

  updateEvents() {
    this.fillEvents().subscribe((events) => this.events$.next(events));
  }

  /**
   * Get Observable to the current user
   * @returns
   */
  getCurrentUser() {
    return this.checkAuth().pipe(switchMap((authenticated) => (authenticated ? this.userSubject$.pipe(take(1)) : of(null))));
  }

  ping() {
    this.http.get('ping').subscribe((st) => {
      this.authenticated = true;
    });
  }

  checkAuth(): Observable<boolean> {
    return this.authenticated !== undefined ? of(this.authenticated) : this.loadSessionUser();
  }

  login(email: string, password: string): Observable<User> {
    return this.http.post<UserApiResponseData>('login', { email, password }).pipe(
      map((response: UserApiResponseData) => new User(response)),
      tap((user) => {
        this.authenticated = true;
        this.userSubject$.next(user);
      })
    );
  }

  logout(): Promise<void> {
    return this.http
      .post<void>('logout', {})
      .pipe(
        tap(() => {
          this.authenticated = false;
          this.userSubject$.next(null);
        })
      )
      .toPromise();
  }

  refreshUser(): Observable<boolean> {
    return this.loadSessionUser();
  }

  /**
   * Updates a user data
   */
  updateUser(user: User, name: string, email: string, msisdn: string) {
    const userModel = {
      ...user,
      name,
      email,
      msisdn,
    };

    return this.http.put<UserApiResponseData>(`users/${user.id}`, userModel).pipe(
      map((response: UserApiResponseData) => new User(response)),
      tap((user) => this.userSubject$.next(user))
    );
  }

  /**
   * Changes a user password
   */
  changePassword(userId: number, password: string) {
    return this.http.put<UserApiResponseData>(`users/${userId}/password`, { password });
  }

  /**
   * Changes a user password
   */
  subscribeToPushNotifications(pso: PushSubscription): Observable<boolean> {
    return this.getCurrentUser().pipe(
      switchMap((currentUser) =>
        currentUser ? this.http.put<boolean>(`users/${currentUser.id}/pso`, { pso: JSON.stringify(pso) }).pipe(map(() => true)) : of(false)
      )
    );
  }

  getEventSubscriptions(): Observable<CmEventChannelSubscription[]> {
    return this.getCurrentUser().pipe(
      switchMap((currentUser) =>
        currentUser ? this.http.get<CmEventChannelSubscription[]>(`users/${currentUser.id}/subscriptions`) : of([])
      )
    );
  }

  /**
   * Changes a user password
   */
  activateUser(email: string, token: string, password: string) {
    return this.http.post<UserApiResponseData>(`activate`, { email, token, password });
  }

  private loadSessionUser() {
    return this.http.get('me').pipe(
      map((response: UserApiResponseData) => new User(response)),
      tap((user) => {
        this.authenticated = true;
        this.userSubject$.next(user);
      }),
      map((user) => Boolean(user)),
      catchError(() => {
        this.authenticated = false;
        return of(false);
      })
    );
  }

  private fillUserScopes() {
    return this.http.get<UserScope[]>(`users/scopes`);
  }

  private fillEvents() {
    return this.http.get<CmEvent[]>(`users/events`);
  }
}
