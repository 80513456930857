import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd, NavigationEnd, ActivatedRoute } from '@angular/router';

import { filter, map, tap, startWith } from 'rxjs/operators';
import { AuthService } from '../authentication/services/auth.service';
import { User } from '../authentication/models/user.model';

interface MainMenuItem {
  title: string;
  icon: string;
  routerLink: string;
  scopes?: string[];
}
@Component({
  selector: 'cm-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  minimized = true;
  user: User;
  userInitials: string;
  isOnline = true;

  menuItems: MainMenuItem[] = [
    { title: 'Sócios', icon: 'recent_actors', routerLink: '/members', scopes: ['membership.*'] },
    {
      title: 'Atividade Desportiva',
      icon: 'sports_soccer',
      routerLink: '/sport-activity',
      scopes: ['sportactivity.*'],
    },
    {
      title: 'Eventos e Bilheteira',
      icon: 'calendar_today',
      routerLink: '/ticketing',
      scopes: ['ticketing.*'],
    },
    // { title: 'Eventos', icon: 'calendar_today', routerLink: '/events' },
    // { title: 'Merchandising', icon: 'shopping_cart', routerLink: '/merchandising' },
    { title: 'Definições', icon: 'settings', routerLink: '/settings', scopes: ['settings.*'] },

    { title: 'Finanças', icon: 'paid', routerLink: '/finances', scopes: ['finances.*'] },
  ];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e: NavigationEnd) => e.url),
        startWith(() => activatedRoute.url)
      )
      .subscribe((url) => {
        this.minimized = url && url.length > 1; // ignore "/"
      });

    this.authService.userChange.pipe(filter((user) => Boolean(user))).subscribe((user) => {
      this.user = user;

      const names = user.name.split(' ').filter((name: string) => name.length);

      this.userInitials =
        names.length > 1 ? `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}` : `${names[0].charAt(0)}${names[0].charAt(1)}`;
    });
  }
  
  ngOnInit() {
    this.updateOnlineStatus();

    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));
  }

  logout() {
    this.authService.logout();
  }

  openMySettings() {
    this.router.navigateByUrl('/me');
  }

  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
  }
}
