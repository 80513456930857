import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, startWith, take, takeUntil } from 'rxjs/operators';
import { NavigationSwipeData, PageEntityAction } from '../../types';
import { NavState } from '../../constants/global.constants';
import { BreadcrumbsService } from '../../services/breadcrumbs.service';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { PermissionsService } from '../../services/permissions.service';

@Component({
  selector: 'cm-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  title: string;

  pageOptions: NavigationSwipeData;
  pageActions: PageEntityAction[] = [];

  private prevNavState: NavState;
  private ngUnsubscribe$ = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private breadcrumbsService: BreadcrumbsService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.updateRouteTitle();

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        startWith(this.activatedRoute)
      )
      .subscribe(() => {
        this.updateRouteTitle();
        this.pageOptions = null;
        this.changeDetectorRef.detectChanges();
        this.router.getCurrentNavigation()?.extras?.state as NavState;
      });

    this.breadcrumbsService
      .getCurrentNavigationDataStreamer()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((data) => {
        this.pageOptions = data;
        this.changeDetectorRef.detectChanges();

        (data?.actions || []).forEach((action) => {
          const scopeStrategy = action.scopeStrategy || 'any';
          const scopes = action.scopes || [];

          const hasPermission$ =
            scopeStrategy === 'any' ? this.permissionsService.userHasAnyScope(scopes) : this.permissionsService.userHasAllScopes(scopes);

          hasPermission$.pipe(take(1)).subscribe((hasPermission) => {
            if (hasPermission) {
              this.pageActions.push(action);
              this.changeDetectorRef.detectChanges();
            }
          });
        });
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  /**
   * Navigate to the previous member edition page
   */
  goToPrev() {
    if (!this.pageOptions?.prev) {
      return;
    }

    this.router.navigateByUrl(`${this.pageOptions.baseUrl}/${this.pageOptions.prev}`, {
      state: this.prevNavState as NavState,
    });
  }

  /**
   * Navigate to the next member edition page
   */
  goToNext() {
    if (!this.pageOptions?.next) {
      return;
    }

    this.router.navigateByUrl(`/${this.pageOptions.baseUrl}/${this.pageOptions.next}`, {
      state: this.prevNavState as NavState,
    });
  }

  private updateRouteTitle() {
    let route: ActivatedRoute = this.router.routerState.root;
    let routeTitle = '';

    while (route!.firstChild) {
      route = route.firstChild;
    }

    if (route.snapshot.data['title']) {
      routeTitle = route!.snapshot.data['title'];
    }

    this.title = routeTitle;
  }
}
