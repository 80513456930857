import { Component, inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'cm-new-version-notification',
  templateUrl: './new-version-notification.component.html',
  styleUrls: ['./new-version-notification.component.scss']
})
export class NewVersionNotificationComponent {
  snackBarRef = inject(MatSnackBarRef);

  skip() {
    this.snackBarRef.dismiss();
  }

  update() {
    window.location.reload();
  }
}
