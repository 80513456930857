import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { UserScopeMatch } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuardService  {
  constructor(private permissionsService: PermissionsService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const scopes = route.data.scopes || [];
    const scopesMatch = route.data.scopesMatch || UserScopeMatch.ANY;

    if (!scopes.length) {
      return of(true);
    }

    switch(scopesMatch) {
      case UserScopeMatch.ANY:
        return this.permissionsService.userHasAnyScope(scopes);
      case UserScopeMatch.ALL:
        return this.permissionsService.userHasAnyScope(scopes);
      case UserScopeMatch.NONE:
        return this.permissionsService.userHasNotScopes(scopes);
      default:
        return this.permissionsService.userHasAnyScope(scopes);
    }
  }
}
