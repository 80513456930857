import { Language } from './global.constants';

export const LOCALS_MAT_DATE_FORMATS = {
  [Language.PT_PT]: {
    parse: {
      dateInput: 'YYYY-MM-DD',
    },
    display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  },
};

export const LOCALS_EVENT_DATETIME_FORMATS = {
  [Language.PT_PT]: {
    parse: {
      dateInput: 'YYYY-MM-DD HH:mm',
    },
    display: {
      dateInput: 'dddd, MMMM DD, YYYY HH:mm',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  },
};

export const LOCALS_EVENT_DATETIME_SHORT_FORMATS = {
  [Language.PT_PT]: {
    parse: {
      dateInput: 'YYYY-MM-DD HH:mm',
    },
    display: {
      dateInput: 'DD/MM/YYYY, HH:mm',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  },
};

export const LOCALS_FILENAME_DATETIME_FORMATS = {
  [Language.PT_PT]:  'YYYYMMDD_HHmmss',
};

