<div class="loading-overlay" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<cm-breadcrumbs></cm-breadcrumbs>

<div class="padding-main-container full-size" *ngIf="!isLoading">
  <div [formGroup]="formGroup" class="controls-container">
    <mat-form-field appearance="outline" floatLabel="never">
      <mat-label>Name</mat-label>
      <input matInput type="text" [formControlName]="formAttributes.name" />

      <mat-error>name-error</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="never">
      <mat-label>E-mail</mat-label>
      <input matInput type="text" [formControlName]="formAttributes.email" />

      <mat-error>email-error</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="never">
      <mat-label>Phone</mat-label>
      <input matInput type="text" [formControlName]="formAttributes.msisdn" />

      <mat-error>msisdn-error</mat-error>
    </mat-form-field>
  </div>

  <div>
    <h3>Notificação de eventos</h3>
    <mat-list>
      <ng-container *ngFor="let domain of domains">
        <div mat-subheader>{{ domain.title }}</div>
        <mat-divider></mat-divider>
        <mat-list-item *ngFor="let event of getDomainEvents(domain.key)">
          <div class="flex-horizontal space-between">
            <span matListItemTitle>{{ event.title }}</span>

            <div>
              <mat-checkbox
                [checked]="userHasChannelSubscription(event.key, ChannelType.PUSH)"
                (change)="toggleNotification(event.key, ChannelType.PUSH, $event.checked)"
              >
                App
              </mat-checkbox>
              <mat-checkbox
                [checked]="userHasChannelSubscription(event.key, ChannelType.EMAIL)"
                (change)="toggleNotification(event.key, ChannelType.EMAIL, $event.checked)"
              >
                E-mail
              </mat-checkbox>
              <mat-checkbox
                [checked]="userHasChannelSubscription(event.key, ChannelType.SMS)"
                (change)="toggleNotification(event.key, ChannelType.SMS, $event.checked)"
              >
                SMS
              </mat-checkbox>
            </div>
          </div>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </div>

  <div [formGroup]="formGroup" class="buttons-container">
    <button mat-raised-button class="submit-btn" (click)="cancel()">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      class="submit-btn"
      (click)="save()"
      [disabled]="formGroup.pristine && !eventSubscriptionsChanged"
    >
      Save
    </button>
  </div>
</div>

<button mat-button class="password-btn" (click)="changePassword()"><span>Change my password </span><mat-icon>password</mat-icon></button>
