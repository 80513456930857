import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigLoaderService } from '../../services/config-loader.service';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestInterceptorService {
  constructor(private snackBar: MatSnackBar, private configLoaderService: ConfigLoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('assets/')) {
      return next.handle(req);
    }

    return this.configLoaderService.getConfig().pipe(
      map((config) =>
        req.clone({
          url: `${config.apiUrl}/${req.url}`,
        })
      ),
      switchMap((request) => next.handle(request)),
      catchError((error) => {
        if (!error.status) {
          const message = navigator.onLine ? `An unknow network error has ocurred` : `Your browser seems to be offline.`;

          this.snackBar.open(`Could not process your request: ${message}`, 'Close', {
            panelClass: 'mat-mdc-snack-bar-container--error',
            verticalPosition: 'top',
            duration: 5000,
          });
        }

        throw error;
      })
    );
  }
}
