import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { UsersService } from 'src/app/settings/users/users.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private authService: AuthService, private usersService: UsersService) {}

  /**
   * Check if current logged User has all the given scopes permission
   */
  userHasAllScopes(scopes: string[] = []): Observable<boolean> {
    return this.authService
      .getCurrentUser()
      .pipe(
        switchMap((user) =>
          this.usersService
            .getUserScopes(user)
            .pipe(map((userScopes) => scopes.every((scope) => userScopes.find((userScope) => new RegExp(scope).test(userScope)))))
        )
      );
  }

  /**
   * Check if current logged User has any of the given scopes permission
   */
  userHasAnyScope(scopes: string[] = []): Observable<boolean> {
    return this.authService
      .getCurrentUser()
      .pipe(
        switchMap((user) =>
          this.usersService
            .getUserScopes(user)
            .pipe(
              map((userScopes) =>
                scopes.some((scope) => userScopes.find((userScope) => new RegExp(scope).test(userScope))) ? true : false
              )
            )
        )
      );
  }

  /**
   * Check if a current logged User has not none of the given scopes permission
   */
  userHasNotScopes(scopes: string[] = []): Observable<boolean> {
    return this.userHasAnyScope(scopes).pipe(map((hasAnyScope) => !hasAnyScope));
  }
}
