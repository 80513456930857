import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { User } from 'src/app/authentication/models/user.model';
import { PermissionsService } from '../services/permissions.service';

@Directive({
  selector: '[ifAllScopes]',
})
export class IfAllScopesDirective {
  isVisible = false;
  user: User;

  @Input() set ifAllScopes(value: string[]) {
    this.permissionsService.userHasAllScopes(value || []).subscribe((shouldShow) => {
      if (shouldShow && !this.isVisible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isVisible = true;
      } else if (!shouldShow && this.isVisible) {
        this.viewContainer.clear();
        this.isVisible = false;
      }
    });
  }

  constructor(
    private permissionsService: PermissionsService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}
}
