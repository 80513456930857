<h2>{{ title }}</h2>

<div *ngIf="pageOptions" class="page-options">
  <button mat-icon-button aria-label="Previous member" (click)="goToPrev()" [disabled]="!pageOptions?.prev">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>

  <button mat-icon-button aria-label="Next member" (click)="goToNext()" [disabled]="!pageOptions?.next">
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>

  <button *ngIf="pageActions.length" mat-icon-button aria-label="Ações" [matMenuTriggerFor]="actionsMenu">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #actionsMenu="matMenu" class="popup-menu" backdropClass="menu-popup-backdrop">
    <button *ngFor="let action of (pageActions)" mat-menu-item (click)="action.callback()">{{ action.label }}</button>
  </mat-menu>
</div>
