import { Moment } from 'moment';
import moment from 'moment';
import { CmEntity, CmEntityApiRequestData, CmEntityApiResponseData, CmEntityApiData, CmEntityStaticFieldInfo } from './cm-entity.model';
import { LOCALS_MAT_DATE_FORMATS } from '../constants/internationalization.constants';
import { selectedLanguage } from '../constants/global.constants';
import { Attachment, AttachmentResource } from '../attachments/attachment.model';
import { CustomFormField } from '../constants/forms.constants';

export enum PersonGender {
  MALE = 'male',
  FEMALE = 'female',
}

export interface PersonApiData extends CmEntityApiData {
  name: string;
  gender?: string;
  birthday?: string;
  email?: string;
  msisdn?: string;
  picture_url?: string;
  notes?: string;
  attachments?: AttachmentResource[];
  custom_fields: { [key: string]: string | number | boolean | Date };
}

export interface PersonApiRequestData extends PersonApiData, CmEntityApiRequestData {}
export interface PersonApiResponseData extends PersonApiData, CmEntityApiResponseData {}

export interface IPerson {
  id?: number;
  name: string;
  gender?: string;
  birthday?: Moment;
  email?: string;
  msisdn?: string;
  pictureUrl?: string;
  notes?: string;
  customFields: { [key: string]: string | number | boolean | Date };
}

export class Person extends CmEntity implements IPerson {
  name: string;
  gender?: string;
  birthday?: Moment;
  email?: string;
  msisdn?: string;
  pictureUrl?: string;
  notes?: string;
  attachments: Attachment[];
  customFields: { [key: string]: string | number | boolean | Date };

  constructor(payload: Person | PersonApiResponseData) {
    super(payload);

    this.name = payload.name;
    this.gender = payload.gender;
    this.birthday = payload.birthday ? moment(payload.birthday) : null;
    this.email = payload.email;
    this.msisdn = payload.msisdn;
    this.pictureUrl = (payload as PersonApiResponseData).picture_url || (payload as Person).pictureUrl;
    this.notes = payload.notes;
    this.attachments = payload.attachments?.map((attachment) => new Attachment(attachment));

    this.customFields = { ...((payload as PersonApiResponseData).custom_fields || (payload as Person).customFields) };
  }

  toRequestModel(customFields: CustomFormField[] = []): PersonApiRequestData {
    if (customFields?.length && this.customFields) {
      for (let field of customFields) {
        if (field.rawType === 'number' && this.customFields[field.name]) {
          const result = parseFloat(this.customFields[field.name] as string);
          this.customFields[field.name] = isNaN(result) ? null : result;
        }

        if (field.rawType === 'boolean' && this.customFields[field.name]) {
          this.customFields[field.name] = Boolean(this.customFields[field.name]);
        }

        if (
          field.rawType === 'text' &&
          this.customFields[field.name] &&
          typeof this.customFields[field.name] === 'string' &&
          (this.customFields[field.name] as string).trim().length === 0
        ) {
          this.customFields[field.name] = null;
        }
      }
    }

    return {
      ...super.toRequestModel(customFields),
      name: this.name,
      gender: this.gender,
      birthday: this.birthday ? this.birthday.format(LOCALS_MAT_DATE_FORMATS[selectedLanguage].parse.dateInput) : null,
      email: this.email,
      msisdn: this.msisdn,
      picture_url: this.pictureUrl,
      notes: this.notes,
      attachments: this.attachments?.map((attachment) => attachment.toRequestModel()),
      custom_fields: this.customFields,
    };
  }

  static getStaticFieldsInfo(): CmEntityStaticFieldInfo[] {
    return [
      ...CmEntity.getStaticFieldsInfo(),
      {
        name: 'name',
        description: 'Complete name',
        renderType: 'text',
      },
      {
        name: 'gender',
        description: 'Male / Female',
        renderType: 'text',
      },
      {
        name: 'email',
        description: 'E-mail',
        renderType: 'text',
      },
      {
        name: 'msisdn',
        description: 'International phone number',
        renderType: 'text',
      },
      {
        name: 'pictureUrl',
        description: 'Picture of the person',
        renderType: 'image',
      },
    ];
  }
}
