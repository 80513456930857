import { Person, PersonApiData, PersonApiRequestData, PersonApiResponseData } from 'src/app/shared/models/person.model';
import { UserRole } from './user-role.model';

export enum UserStatus {
  INACTIVE = 0,
  PENDING_APPROVAL = 1,
  PENDING_ACTIVATION = 2,
  ACTIVE = 3,
  BLOCKED = 4,
  DELETED = 5,
}

export interface UserApiData extends PersonApiData {
  user_role_id: number;
  job?: string;
  manager?: number;
  status: number;
  push_subscription_object?: string;
}

interface UserApiRequestData extends UserApiData, PersonApiRequestData {}
export interface UserApiResponseData extends UserApiData, PersonApiResponseData {
  scopes: string[];
}

export class User extends Person {
  userRole: UserRole;
  userRoleId: number;
  job: string;
  manager: number;
  status: UserStatus;
  pushSubscriptionObject?: string;

  constructor(payload: User | UserApiResponseData) {
    super(payload);

    this.userRoleId = (payload as UserApiResponseData).user_role_id || (payload as User).userRoleId;
    this.job = payload.job;
    this.manager = payload.manager;
    this.status = payload.status;
    this.pushSubscriptionObject = (payload as UserApiResponseData).push_subscription_object || (payload as User).pushSubscriptionObject;
  }

  toRequestModel(): UserApiRequestData {
    return {
      ...super.toRequestModel(),
      job: this.job,
      user_role_id: this.userRoleId,
      manager: this.manager,
      status: this.status,
      push_subscription_object: this.pushSubscriptionObject,
    };
  }
}
