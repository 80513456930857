/**
 * Represents a generic entity FROM/TO the Club Manager workspace
 */

import { CustomFormField } from '../constants/forms.constants';

export enum CmEntityApiRequestAction {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export interface CmEntityApiData {
  last_update?: Date; // When this entity was last updated
  last_update_by?: number; // Who last updated this entity? (id)
}

export interface CmEntityApiRequestData extends CmEntityApiData {
  id?: number; // database autoincrement ID
}

export interface CmEntityApiResponseData extends CmEntityApiData {
  cm_hash?: string;
  id?: number; // database autoincrement ID
}

export interface CmEntityApiRequestDataWithAction {
  action: CmEntityApiRequestAction;
  data: CmEntityApiRequestData;
}

export type CmEntityStaticFieldInfo = {
  name: string;
  description: string;
  renderType: 'text' | 'image' | 'qrcode' | 'boolean';
};

export class CmEntity {
  id?: number; // database autoincrement ID
  cmHash?: string; // id hash for direct link auth
  lastUpdate?: Date; // When this entity was last updated
  lastUpdateBy?: number; // Who last updated this entity? (id)

  constructor(obj?: CmEntity | CmEntityApiResponseData) {
    this.id = obj?.id;
    this.cmHash = (obj as CmEntity)?.cmHash || (obj as CmEntityApiResponseData)?.cm_hash;
    this.lastUpdateBy = (obj as CmEntity)?.lastUpdateBy || (obj as CmEntityApiResponseData)?.last_update_by;

    const lastUpdate = (obj as CmEntity)?.lastUpdate || (obj as CmEntityApiResponseData)?.last_update;
    this.lastUpdate = lastUpdate ? new Date(lastUpdate) : null;
  }

  /**
   * Merge all props from one entity in this one
   * @param entity
   */
  merge(entity: CmEntity) {
    for (let prop in entity) {
      if (Object.prototype.hasOwnProperty.call(this, prop)) {
        this[prop] = entity[prop];
      }
    }
  }

  toRequestModel(customFields: CustomFormField[] = []): CmEntityApiRequestData {
    return {
      id: this.id,
    };
  }

  static getStaticFieldsInfo(): CmEntityStaticFieldInfo[] {
    return [
      {
        name: 'id',
        description: 'Database unique identifier',
        renderType: 'text',
      },
      {
        name: 'lastUpdate',
        description: 'Date of the last changes on this registry',
        renderType: 'text',
      },
      {
        name: 'lastUpdateBy',
        description: 'Who made the last changes on this registry',
        renderType: 'text',
      },
    ];
  }
}
